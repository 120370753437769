var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('CCard',[_c('CCardHeader',[_vm._v(" List des factures "),_c('div',{staticClass:"card-header-actions"},[_c('a',{staticClass:"card-header-action",attrs:{"href":"#","rel":"noreferrer noopener","target":"_blank"}},[_c('small',{staticClass:"text-muted"})])])]),_c('CCardBody',{},[_c('CDataTable',{staticClass:"m-0 table-borderless",attrs:{"hover":"","responsive":false,"loading":_vm.isLoading,"items":_vm.items,"fields":_vm.factureFields,"header":false,"cleaner":"","table-filter":"","items-per-page-select":"","items-per-page":5,"pagination":""},scopedSlots:_vm._u([{key:"objet",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',[_c('CLink',{staticClass:"text-decoration-none titre-fact",attrs:{"to":'/factures/' + item.numero}},[_vm._v(" "+_vm._s(item.objet)+" ")])],1),_c('div',{staticClass:"small text-muted mt-1"})])}},{key:"numero",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"numero-fact"},[_vm._v("N°: "+_vm._s(item.numero))])])}},{key:"cout",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('div',{staticClass:"text-info"},[_vm._v(_vm._s(item.cout)+" $")])])}},{key:"activity",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CRow',{staticClass:"ml-4 d-flex justify-content-arround flex-nowrap"},[_c('PopUpFacture',{attrs:{"add":false,"initData":item,"edition":_vm.edition,"update":true},on:{"addnew-ok":_vm.LoadFacture}}),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Export to PDF'),expression:"'Export to PDF'"}],staticClass:"mx-1",attrs:{"color":"info","variant":"ghost","shape":"pill","size":"sm"}},[_c('CIcon',{staticClass:"mr-1 text-primary",attrs:{"name":"cilCloudDownload"}})],1),_c('CButton',{staticClass:"mx-1 text-danger",attrs:{"color":"dark","variant":"ghost","shape":"pill","size":"sm"},on:{"click":function($event){return _vm.DeleteModalOn(item)}}},[_c('CIcon',{staticClass:"mr-1 text-danger",attrs:{"name":"cil-x-circle"}})],1)],1)],1)}}])})],1)],1),_c('CModal',{attrs:{"title":'Confirmer la suppression de la facture N°: ' + _vm.dataDelete.numero,"color":"danger","show":_vm.deleteModal},on:{"update:show":function($event){_vm.deleteModal=$event}}},[_vm._v(" êtes vous sûre de vouloir supprimer ce contenu? "),_c('br'),_c('small',{staticClass:"mt-2 text-center"},[_vm._v("Cette action est irréversible.")]),_c('template',{slot:"footer"},[_c('div',{staticClass:"d-flex justify-content-end mr-3"},[_c('CButton',{staticClass:"mx-1",attrs:{"color":"light"},on:{"click":function($event){_vm.deleteModal = false}}},[_vm._v("Cancel")]),_c('CButton',{staticClass:"mx-1",attrs:{"color":"danger","desabled":""},on:{"click":_vm.DeleteFacture}},[_vm._v("Supprimer "),(_vm.spinner)?_c('CSpinner',{staticStyle:{"width":"1rem","height":"1rem"},attrs:{"size":"sm","tag":"small","color":"primary"}}):_vm._e()],1)],1)])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }